<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
			<g>
				<defs>
					<path id="SVGID_1_" d="M99,186.1h51.1c0.8,0,1.4,0.6,1.4,1.4c0.1,6,1.3,30.1,16.5,43.7c1,0.9,0.3,2.4-0.9,2.4H83
						c-1.3,0-1.9-1.6-1-2.4c5.4-4.7,15.5-17.1,15.5-43.6C97.6,186.8,98.2,186.1,99,186.1z"/>
				</defs>
				<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
				<clipPath id="SVGID_2_">
					<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
				</clipPath>
			</g>
			<g>
				<path d="M239.6,178.8H10.4c-5.5,0-9.9-4.4-9.9-9.9V26.2c0-5.5,4.4-9.9,9.9-9.9h229.2c5.5,0,9.9,4.4,9.9,9.9v142.6
					C249.5,174.3,245.1,178.8,239.6,178.8z M19.6,159.6h210.7V35.5H19.6V159.6z"/>
			</g>
		</g>
    </icon-base>
</template>

<script>
export default {
name: 'iconDevices',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
